// src/components/ArtistList.js
import React from 'react'

const ArtistList = ({ data }) => {
  return (
    <div id="artist-list-wrapper">
      {[1, 2, 3, 4, 5, 6, 7].map(num => {
        const columnTitle = data[`columnTitle${ num }`]
        const columnArtists = data[`column${ num }Artists`]

        // Check if column title exists and is not empty
        if (columnTitle && columnTitle.trim() !== '') {
          return (
            <div className="column" key={num}>
              <div className="title">{columnTitle}</div>
              <div className="links">
                {columnArtists.map((artist, i) => (
                  <a key={i} href={artist.slug}>
                    {artist.fullName}
                  </a>
                ))}
              </div>
            </div>
          )
        }
        return null
      })}
    </div>
  );
};

export default ArtistList
